import firebase from "firebase";
import "firebase/firestore";
// a1lci65L9fsS5RD6iWl6
const yyschool = "/schools/3rjIXFglv9A2d4u15wTV/";

const REAL_APP = true;
const EYESH_APP = false;
const COOKING_APP = true;
export const omittedDays = [
  {
    month: 11,
    day: 6,
  },
  {
    month: 11,
    day: 7,
  },
  {
    month: 11,
    day: 8,
  },
  {
    month: 11,
    day: 9,
  },
];

const config = !COOKING_APP
  ? {
      apiKey: "AIzaSyDUoVVpLKmXIoFLtToD64dZzeCHFyf7MtI",
      authDomain: "digitalschool-3f1e9.firebaseapp.com",
      projectId: "digitalschool-3f1e9",
      storageBucket: "digitalschool-3f1e9.appspot.com",
      messagingSenderId: "667372416860",
      appId: "1:667372416860:web:036c4483d3da2d923b9b99",
    }
  : {
      // apiKey: "AIzaSyAUUz2RYnfvWBrYBeuad1Ul5uN0NZBSCx4",
      // authDomain: "khuvsgul-8efed.firebaseapp.com",
      // projectId: "khuvsgul-8efed",
      // storageBucket: "khuvsgul-8efed.appspot.com",
      // messagingSenderId: "543801818833",
      // appId: "1:543801818833:web:30dadc849c0a74fdcf4ff0"

      apiKey: "AIzaSyDPEoSiIyIIytU47QG4IrpoXeEOoe3J9vU",
      authDomain: "udiin-hool-5a2b1.firebaseapp.com",
      projectId: "udiin-hool-5a2b1",
      storageBucket: "udiin-hool-5a2b1.appspot.com",
      messagingSenderId: "422346168985",
      appId: "1:422346168985:web:f56a8c10e3883388e4f608",
      measurementId: "G-BD475JKR5X"

      // apiKey: "AIzaSyDUoVVpLKmXIoFLtToD64dZzeCHFyf7MtI",
      // authDomain: "digitalschool-3f1e9.firebaseapp.com",
      // projectId: "digitalschool-3f1e9",
      // storageBucket: "digitalschool-3f1e9.appspot.com",
      // messagingSenderId: "667372416860",
      // appId: "1:667372416860:web:036c4483d3da2d923b9b99",
    };

const eyeshTmp = {
  eyeshApiKey: "AIzaSyDcriWZpZ_4Tj3YVfyvUKOdHpnCqiV579g",
  eyeshAuthDomain: "eyesh800-ae733.firebaseapp.com",
  eyeshProjectId: "eyesh800-ae733",
  eyeshStorageBucket: "eyesh800-ae733.appspot.com",
  eyeshMessagingSenderId: "637398427967",
  eyeshAppId: "1:637398427967:web:d5cf9168e0245b9b2bcb8a",
};

const eyesh800 = {
  apiKey: eyeshTmp.eyeshApiKey,
  authDomain: eyeshTmp.eyeshAuthDomain,
  projectId: eyeshTmp.eyeshProjectId,
  storageBucket: eyeshTmp.eyeshStorageBucket,
  messagingSenderId: eyeshTmp.eyeshMessagingSenderId,
  appId: eyeshTmp.eyeshAppId,
};

const fbeyesh800 = firebase.initializeApp(eyesh800, "secondary");
export const dbeyesh800 = fbeyesh800.firestore();

const digitalschoolstorage = {
  apiKey: "AIzaSyBUsdngoliI5cloSlIoqpqRMpOJzyEtJ6M",
  authDomain: "digitalschoolstorage.firebaseapp.com",
  projectId: "digitalschoolstorage",
  storageBucket: "digitalschoolstorage.appspot.com",
  messagingSenderId: "370821067954",
  appId: "1:370821067954:web:b1ee09e85f969615ffc5ab",

  // apiKey: "AIzaSyDUoVVpLKmXIoFLtToD64dZzeCHFyf7MtI",
  // authDomain: "digitalschool-3f1e9.firebaseapp.com",
  // projectId: "digitalschool-3f1e9",
  // storageBucket: "digitalschool-3f1e9.appspot.com",
  // messagingSenderId: "667372416860",
  // appId: "1:667372416860:web:036c4483d3da2d923b9b99",
  // measurementId: "G-GD56WG73B9"
};

const fbDSStorage = firebase.initializeApp(
  digitalschoolstorage,
  "digitalschoolstorage"
);
export const dbDSStorage = fbDSStorage.firestore();

const app = firebase.initializeApp(config);

// const dbold = firebase
//   .initializeApp({
//     apiKey: "AIzaSyDryFs4FXGuDSmsSQ5-OQujrxcAYqsGfAI",
//     authDomain: "uvsaimag-eyesh.firebaseapp.com",
//     projectId: "uvsaimag-eyesh",
//     storageBucket: "uvsaimag-eyesh.appspot.com",
//     messagingSenderId: "527505746482",
//     appId: "1:527505746482:web:f4c41c4083ed163c48aa2b",
//     measurementId: "G-ZZEV2RY52V"
//   },
//     "uvsaimag-eyesh"
//   )
//   .firestore();

// firebase utils
const firestore = firebase.firestore;
const db = firebase.firestore();
const auth = firebase.auth();
export const analytics = app.analytics();
const currentUser = auth.currentUser;

var googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.addScope("email");

const storage = firebase.storage;
const storageRef = firebase.storage().ref();
export {
  db,
  auth,
  currentUser,
  googleProvider,
  storage,
  storageRef,
  firestore,
  yyschool,
  COOKING_APP,
  EYESH_APP,
  REAL_APP,
};
