import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import {
  store
} from './store.js'
import router from './router'
import VueCookies from 'vue-cookies'
import 'chartist/dist/chartist.min.css'
import DashboardPlugin from "./plugins/dashboard-plugin";
import VueSocialSharing from 'vue-social-sharing';
import VuePlyr from 'vue-plyr'
// import VueHtmlToPaper from 'vue-html-to-paper';
import 'vue-plyr/dist/vue-plyr.css'

 
import VPageGuide from 'vue-page-guide'

Vue.config.productionTip = false;

Vue.use(VPageGuide)

const fb = require('./firebaseConfig.js')

Vue.use(VueSocialSharing);
Vue.use(require('vue-chartist'))
Vue.use(DashboardPlugin);

Vue.use(VueCookies)
Vue.use(require('vue-moment'));
Vue.use(VuePlyr, {
  plyr: {}
})
// Vue.use(VueHtmlToPaper, {
//   name: '_blank',
//   specs: [
//     'fullscreen=yes',
//     'titlebar=yes',
//     'scrollbars=yes'
//   ],
//   styles: [
//     'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
//     'https://unpkg.com/kidlat-css/css/kidlat.css'
//   ]
// });
// import YQuestionnaires from '@/components/YQuestionnaires'
// import YQuesionDetail from '@/components/YQuesionDetail'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
Vue.config.productionTip = false


Vue.$cookies.config('7d')
Vue.config.productionTip = false

import myfunction from "@/components/global/myfunction.js";

fb.auth.onAuthStateChanged(async user => {
  var doc;
  var userObj;
  var ddoc;
  if (user) {
    store.set("currentUser", user)
    doc = await fb.db
      .collectionGroup("teachers")
      .where("email", "==", user.email)
      .limit(1)
      .get();
    if (doc.empty) {
      doc = await fb.db
        .collectionGroup("students")
        .where("email", "==", user.email)
        .limit(1)
        .get();
    }
    if (!doc.empty) {
      userObj = doc.docs[0];
      if (userObj.exists) {
        let userData = userObj.data();
        userData.id = userObj.id;
        userData.ref = userObj.ref;
        // console.log(userData)
        if (userData.school) {
          userData.ppschool = userData.school.ref.path
          await userData.school.ref.get().then((doc) => {
            userData.school = doc.data()
            userData.school.id = doc.id
            userData.school.ref = doc.ref
         
          })

        } else {
          if (userData.ref.parent.parent) {
            userData.ppschool = userData.ref.parent.parent.path
            // userData.ppschool = "schools/YodtLTjL7GNxY9NBjWco" 
            //userData.ppschool ="schools/QkJ6bvs865mbXINzHhKB"
            //DEBUG
            //userData.ppschool = "schools/nJ0ML9ltkfYarYKzKgI7" //zavkhan numrog
            // userData.ppschool = "schools/wXaBdoO4uNHp5HYUJbpK" 
            // userData.ppschool = "schools/wXaBdoO4uNHp5HYUJbpK"

            await fb.db.doc(userData.ppschool).get().then((doc) => {
              userData.school = doc.data()
              userData.school.id = doc.id
              userData.school.ref = doc.ref
           
            })

          } else {
            if (userData.role == 'aspecialist') {
              userData.school = null
              userData.ppschool = null
            } else {
              if (store.state.selectedSchoolRef) {
                userData.ppschool = store.state.selectedSchoolRef

                // fb.db.doc(store.state.selectedSchoolRef).get().then((doc) => {
                //   let school = doc.data()
                //   school.id = doc.id
                //   school.ref = doc.ref
                //   userData.school = school
                // })


                ddoc = await fb.db.doc(store.state.selectedSchoolRef).get();
                let school = ddoc.data()
                if (school) {
                  school.id = ddoc.id
                  school.ref = ddoc.ref
                  userData.school = school
                }
              } else {
                userData.ppschool = null
              }
            }
            if(userData.cityCode){
              fb.db.collection("schools").where("deleted", "==", false).orderBy("name", "asc").onSnapshot((schools) => {
                store.state.availableSchools = []
                var counter = 0
                schools.forEach((doc) => {
                  let school = doc.data()
                  school.id = doc.id
                  school.ref = doc.ref
                  // school.name2 = counter   
                  if(school.cityCode&&userData.cityCode&&school.cityCode==userData.cityCode){
                    counter++ 
                    school.name2 = counter + ". " + school.name + (school.city?' /' +school.city+'/':'')
                    school.index = counter
                    store.state.availableSchools.push(school)
                  } 
                })
              })
            }else{
              fb.db.collection("schools").where("deleted", "==", false).orderBy("name", "asc").onSnapshot((schools) => {
                store.state.availableSchools = []
                var counter = 0
                schools.forEach((doc) => {
                  let school = doc.data()
                  school.id = doc.id
                  school.ref = doc.ref
                  counter++ 
                    school.name2 = counter + ". " + school.name + (school.city?' /' +school.city+'/':'')
                    school.index = counter
                    store.state.availableSchools.push(school)
                })
              })
            }
          }
        }
        // console.log('department-' + userData.school.currentYear)
        // console.log(['departmentName-' + userData.school.currentYear])
        // console.log(userData)
        // if (userData.school && userData.role == 'student' && !userData.departmentIndex) {
        //   await userData['departmentName-' + userData.school.currentYear].get().then((doc) => {
        //     userData.departmentIndex = doc.data().index
        //     userData.ref.update({
        //       departmentIndex: userData.departmentIndex
        //     })
        //   })
        // }

        store.set("userData", userData);
      }
    }
  } else {
    if (store.state.mendleUserRef) {

      userObj = await fb.db
        .doc(store.state.mendleUserRef)
        .get();
      if (userObj.exists) {
        let userData = userObj.data();
        userData.id = userObj.id;
        userData.ref = userObj.ref;



        // fb.dbDSStorage.collection("testtest").get().then((docs)=>{
        //   console.log(docs)
        // }) TODO

        if (userData.school) {
          userData.ppschool = userData.school.ref.path
          await userData.school.ref.get().then((doc) => {
            userData.school = doc.data()
            userData.school.id = doc.id
            userData.school.ref = doc.ref

          })

        } else {
          if (userData.ref.parent.parent) {

            userData.ppschool = userData.ref.parent.parent.path
            // userData.ppschool = "schools/YodtLTjL7GNxY9NBjWco" 
            //userData.ppschool ="schools/QkJ6bvs865mbXINzHhKB"
            //DEBUG
            //userData.ppschool = "schools/nJ0ML9ltkfYarYKzKgI7" //zavkhan numrog
            // userData.ppschool = "schools/wXaBdoO4uNHp5HYUJbpK" 
            // userData.ppschool = "schools/wXaBdoO4uNHp5HYUJbpK"

            await fb.db.doc(userData.ppschool).get().then((doc) => {
              userData.school = doc.data()
              userData.school.id = doc.id
              userData.school.ref = doc.ref
            })

          } else {
            if (userData.role == 'aspecialist') {
              userData.school = null
              userData.ppschool = null
            } else {
              if (store.state.selectedSchoolRef) {
                userData.ppschool = store.state.selectedSchoolRef

                // fb.db.doc(store.state.selectedSchoolRef).get().then((doc) => {
                //   let school = doc.data()
                //   school.id = doc.id
                //   school.ref = doc.ref
                //   userData.school = school
                // })


                ddoc = await fb.db.doc(store.state.selectedSchoolRef).get();
                let school = ddoc.data()
                if (school) {
                  school.id = ddoc.id
                  school.ref = ddoc.ref
                  userData.school = school
                }
              } else {
                userData.ppschool = null
              }
            }
            fb.db.collection("schools").orderBy("name", "asc").onSnapshot((schools) => {
              store.state.availableSchools = []
              var counter = 0
              schools.forEach((doc) => {
                counter++
                let school = doc.data()
                school.id = doc.id
                school.ref = doc.ref
                // school.name2 = counter + ". " + school.name
                school.name2 = counter + ". " + (school.name + school.city?' /' +school.city+'/':'')
                school.index = counter
                if(school.cityCode&&this.userData.cityCode&&school.cityCode==this.userData.cityCode)
                  store.state.availableSchools.push(school)
                //else store.state.availableSchools.push(school)
              })
            })
          }
        }
        if (userData.school && userData.role == 'student' && !userData.departmentIndex) {
          await userData['department-' + userData.school.currentYear].get().then((doc) => {
            userData.departmentIndex = doc.data().index
            userData.ref.update({
              departmentIndex: userData.departmentIndex
            })
          })
        }
        store.set("userData", userData);
      }

      //store.dispatch('clearData')
    }
    // if (user) {
    //   store.commit('setCurrentUser', user)
    //   store.dispatch('fetchUserProfile')

    // } else {
    //   store.dispatch('clearData')
    // }
  }
  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
    mixins: [myfunction],
    created() {
      //  fb.db.collection("_news_bagshsystem").doc("zar").get
    }
  }).$mount('#app')
})